import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/ArtistDashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Linkify from "react-linkify";
import io from "socket.io-client";
import ProjectDropzone from "./ProjectDropzone"; // Import the component
import NavigationBar from "./Navbar"; // Adjust the import path according to your file structure
import { apiInstance, socket } from "../config/config"; // Adjust the import path according to your project structure

const ArtistDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempCompletions, setTempCompletions] = useState({});

  const hashEmail = (email) => {
    let hashedEmail = "";
    for (let i = 0; i < email.length; i++) {
      hashedEmail += email.charCodeAt(i).toString(16);
    }
    return hashedEmail;
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const username = localStorage.getItem("username");
      try {
        const response = await apiInstance.get(`/api/forArtist/${username}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setProjects(response.data);
      } catch (error) {
        console.error("Failed to fetch projects", error);
        toast.error("Failed to fetch projects");
      }
    };

    fetchProjects();
    // Update the connection URL to the correct port

    socket.on("projectUpdated", (updatedProject) => {
      fetchProjects(); // Re-fetch projects when an update is received
    });

    return () => socket.disconnect(); // Clean up on component unmount
  }, []);

  const filteredProjects = projects.filter(
    (project) =>
      project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const customComponentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  const decodeEmail = (encodedEmail) => {
    return atob(encodedEmail); // Decode the email
  };

  const handleCompletionChange = (projectId, value) => {
    setTempCompletions((prev) => ({ ...prev, [projectId]: value }));
  };

  const updateCompletion = async (projectId) => {
    const newPercentage = tempCompletions[projectId];
    if (newPercentage != null) {
      // Check if there's a value for this project
      try {
        const response = await apiInstance.post(
          `/api/projects/${projectId}/completion`,
          {
            completionPercentage: newPercentage,
          }
        );
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project._id === projectId
              ? { ...project, completionPercentage: newPercentage }
              : project
          )
        );
        toast.success("Completion updated successfully");
        // Optionally clear the local change after updating
        setTempCompletions((prev) => {
          const newState = { ...prev };
          delete newState[projectId];
          return newState;
        });
      } catch (error) {
        console.error("Failed to update completion percentage", error);
        toast.error("Failed to update completion percentage");
      }
    }
  };

  return (
    <div className="artist-dashboard-container">
      <NavigationBar />
      <ToastContainer />
      <div className="search-and-table-container">
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <div className="table-container">
          <table className="projects-table">
            <thead>
              <tr>
                <th className="project-name">Project Name</th>
                <th className="image-upload">Image Upload</th>
                <th className="description">Description</th>
                <th className="start-date">Start Date</th>
                <th className="end-date">End Date</th>
                <th className="complete">Complete %</th>
                <th className="last-uploaded-date">Last Update sent</th>

              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project) => (
                <tr key={project._id}>
                  <td className="project-name">{project.projectName}</td>
                  <td className="image-upload">
                    <ProjectDropzone
                      projectId={project._id}
                      projectEmail={decodeEmail(project.clientEmail)} // Use atob for decoding
                      projectName={project.projectName}
                    />
                  </td>
                  <td style={{ whiteSpace: "pre-line" }}>
                    <Linkify componentDecorator={customComponentDecorator}>
                      {/* Display project description */}
                      {project.description}

                      {/* Display comments with numbering */}
                      {project.comments && project.comments.length > 0 && (
                        <ul style={{ paddingLeft: 0, listStyle: "none" }}>
                          {project.comments.map((comment, index) => (
                            <li key={index} style={{ marginTop: "10px" }}>
                              <strong>Comment {index + 1}:</strong>
                              <br />
                              {comment.text}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Linkify>
                  </td>
                  <td className="start-date">
                    {new Date(project.startDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="end-date">
                    {new Date(project.endDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="completion">
                    <input
                      type="number"
                      value={
                        tempCompletions[project._id] !== undefined
                          ? tempCompletions[project._id]
                          : project.completionPercentage
                      }
                      onChange={(e) =>
                        handleCompletionChange(project._id, e.target.value)
                      }
                      min="0"
                      max="100"
                      className="completion-input"
                    />
                    <button
                      onClick={() => updateCompletion(project._id)}
                      className="update-button"
                    >
                      Update
                    </button>
                  </td>
                  <td className="last-uploaded-date">{project.lastUploadedDate || 'No info'}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ArtistDashboard;
