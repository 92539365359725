import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import ArtistDashboard from './components/ArtistDashboard';
// Import additional components
import Attendance from './components/Attendance';
import PaySlip from './components/payslip';
import Leave from './components/Leave';
import AnnualReport from './components/AnnualReport';
import Sheet from './components/Sheet';
import Users from './components/Users';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/artist-dashboard" element={<ArtistDashboard />} />
        {/* Add new routes here */}
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/payslip" element={<PaySlip />} />
        <Route path="/leave" element={<Leave />} />
        <Route path="/annual" element={<AnnualReport />} />
        <Route path="/sheet" element={<Sheet />} />
        <Route path="/Users" element={<Users />} />
      </Routes>
    </Router>
  );
}

export default App;
