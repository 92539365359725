// Attendance.js
import React from 'react';

const PaySlip = () => {
  return (
    <div>
      <h2>PaySlip</h2>
      {/* Content for Attendance page */}
    </div>
  );
}

export default PaySlip;
