import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "../css/Annualreport.css";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import NavigationBar from "./Navbar"; // Adjust the import path according to your file structure
import { apiInstance, socket } from "../config/config"; // Adjust the import path according to your project structure

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AnnualReport = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isEditing, setIsEditing] = useState(false); // Tracks if editing mode is active
  const [editInvoiceId, setEditInvoiceId] = useState(null); // Tracks which invoice is being edited
  const [editLineItems, setEditLineItems] = useState([]); // Temporary state for editable line items

  const years = Array.from({ length: 13 }, (_, i) => currentYear + i - 10); // From 2023 to 2035
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await apiInstance.get("/api/invoices");
        // Axios automatically handles the response, so you directly access `response.data`
        setInvoices(response.data); // Assuming the endpoint returns an array of invoices
      } catch (error) {
        // Axios wraps the error response in `error.response`
        console.error(
          "Error fetching invoices:",
          error.response ? error.response.data : error
        );
      }
    };

    fetchInvoices();

    socket.on("connect", () => {
      console.log("Connected to server with id:", socket.id);
    });

    socket.on("invoicePaid", (newInvoice) => {
      setInvoices((prevInvoices) => [...prevInvoices, newInvoice]);
    });

    // Cleanup on component unmount
    return () => {
      socket.off("connect");
      socket.off("invoicePaid");
    };
  }, []); // Assuming `apiInstance_1` and `socket` are stable references

  useEffect(() => {
    // Calculate total amount when invoices change
    const sum = invoices.reduce(
      (total, invoice) => total + (invoice.amountearned || 0),
      0
    );
    setTotalAmount(sum);
  }, [invoices]);

  useEffect(() => {
    const monthIndex = months.indexOf(selectedMonth) + 1;
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.startDate);
      return (
        invoiceDate.getFullYear() === selectedYear &&
        (selectedMonth === "" || invoiceDate.getMonth() + 1 === monthIndex)
      );
    });
    setFilteredInvoices(filtered);
  }, [selectedYear, selectedMonth, invoices]);
  const [chartData, setChartData] = useState({});

  const prepareChartData = () => {
    // Check if selectedMonth is valid
    const currentMonthIndex = months.indexOf(selectedMonth) + 1;
    if (currentMonthIndex === 0) return; // Exit if selectedMonth is not valid

    const previousMonthIndex =
      currentMonthIndex === 1 ? 12 : currentMonthIndex - 1;
    const yearOfPreviousMonth =
      currentMonthIndex === 1 ? selectedYear - 1 : selectedYear;

    const generateAllDatesForMonth = (year, month) => {
      const dates = [];
      const date = new Date(year, month - 1, 1);
      while (date.getMonth() === month - 1) {
        dates.push(
          `${year}-${String(month).padStart(2, "0")}-${String(
            date.getDate()
          ).padStart(2, "0")}`
        );
        date.setDate(date.getDate() + 1);
      }
      return dates;
    };

    const allCurrentMonthDates = generateAllDatesForMonth(
      selectedYear,
      currentMonthIndex
    );
    const allPreviousMonthDates = generateAllDatesForMonth(
      yearOfPreviousMonth,
      previousMonthIndex
    );

    // Ensure dates are generated
    if (!allCurrentMonthDates.length || !allPreviousMonthDates.length) return;

    // Initialize totals for each day to zero for both months
    // This step should not cause the error as we're directly creating objects with dates as keys
    const currentMonthTotals = allCurrentMonthDates.reduce(
      (acc, date) => ({ ...acc, [date]: 0 }),
      {}
    );
    const previousMonthTotals = allPreviousMonthDates.reduce(
      (acc, date) => ({ ...acc, [date]: 0 }),
      {}
    );

    invoices.forEach((invoice) => {
      const invoiceDate = new Date(invoice.startDate);
      const dateKey = `${invoiceDate.getFullYear()}-${String(
        invoiceDate.getMonth() + 1
      ).padStart(2, "0")}-${String(invoiceDate.getDate()).padStart(2, "0")}`;

      if (currentMonthTotals.hasOwnProperty(dateKey)) {
        currentMonthTotals[dateKey] += invoice.amountearned;
      }
      if (previousMonthTotals.hasOwnProperty(dateKey)) {
        previousMonthTotals[dateKey] += invoice.amountearned;
      }
    });

    // Prepare datasets
    const currentMonthData = allCurrentMonthDates.map(
      (date) => currentMonthTotals[date] / 100
    );
    const previousMonthData = allPreviousMonthDates.map(
      (date) => previousMonthTotals[date] / 100
    );

    const labels = allCurrentMonthDates.map((date) =>
      new Date(date).toLocaleDateString()
    );

    setChartData({
      labels,
      datasets: [
        {
          label: "Previous Month",
          data: previousMonthData,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
        },
        {
          label: "Selected Month",
          data: currentMonthData,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
        },
      ],
    });
  };

  useEffect(() => {
    setTotalAmount(
      invoices.reduce((acc, invoice) => acc + (invoice.amountearned || 0), 0)
    );
    prepareChartData();
  }, [invoices, selectedYear, selectedMonth]);

  const updateLineItems = async (invoiceId, lineItems) => {
    try {
      await apiInstance.post("/api/invoices/updateLineItems", {
        invoiceId,
        lineItems,
      });
      console.log("Update successful");
      setIsEditing(false);
      setEditInvoiceId(null);
      // Refresh your invoice list here or directly update the state
    } catch (error) {
      console.error(
        "Error updating line items",
        error.response ? error.response.data : error
      );
    }
  };

  const handleEditInvoice = (invoice) => {
    setIsEditing(true);
    setEditInvoiceId(invoice._id); // Assuming _id is your identifier
    setEditLineItems([...invoice.lineItems]);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setEditInvoiceId(null);
    setEditLineItems([]);
  };

  return (
    <div className="annual-report-container">
      <NavigationBar />

      <div className="annual-selection-container">
        <label>
          <select
            className="annual-selection-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </label>
        <label>
          <select
            className="annual-selection-select"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">All Months</option>
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </label>
      </div>

      {filteredInvoices.length > 0 ? (
        <>
          <table className="annual-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Amount Paid</th>
                <th>Description</th>
                <th>Start Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.projectName}</td>
                  <td>{invoice.customerEmail}</td>
                  <td>${invoice.amountPaid / 100}</td>
                  <td>
                    <div className="inline-edit-container">
                      <div>
                        {isEditing && editInvoiceId === invoice._id ? (
                          <ul>
                            {editLineItems.map((item, itemIndex) => (
                              <li key={itemIndex}>
                                <input
                                  type="text"
                                  value={item.description}
                                  onChange={(e) => {
                                    const newLineItems = [...editLineItems];
                                    newLineItems[itemIndex].description =
                                      e.target.value;
                                    setEditLineItems(newLineItems);
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {invoice.lineItems.map((item, itemIndex) => (
                              <li key={itemIndex}>{item.description}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <div className="btn-container">
                        {isEditing && editInvoiceId === invoice._id ? (
                          <>
                            <button
                              onClick={() =>
                                updateLineItems(editInvoiceId, editLineItems)
                              }
                              className="btn btn-save"
                            >
                              Save
                            </button>
                            <button
                              onClick={cancelEdit}
                              className="btn btn-cancel"
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() => handleEditInvoice(invoice)}
                            className="btn btn-edit"
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>{invoice.startDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="annual-total-amount">
            <strong>Total Amount Paid: ${totalAmount / 100}</strong>
          </div>
        </>
      ) : (
        <p>No invoices found for this period.</p>
      )}

      <div className="annual-chart-container">
        {chartData && chartData.labels && chartData.datasets.length > 0 ? (
          <Line data={chartData} options={{ maintainAspectRatio: false }} />
        ) : (
          <p>No data available for the chart.</p>
        )}
      </div>
    </div>
  );
};

export default AnnualReport;
