import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import "../css/navbar.css";

const NavigationBar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    const role = localStorage.getItem('role');
    const handleLogout = (e) => {
        e.preventDefault(); // Prevent the default anchor link behavior
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        window.location.href = "/";
    };

    return (
        <div className="navbar-container">
            <div className="navbar-brand">Spot Auto</div>
            <button className="hamburger-menu" onClick={toggleSidebar}>☰</button>
            <ul className={`nav-links ${isSidebarOpen ? 'show' : ''}`}>
                {role === 'admin' && (
                    <>
                        <li><a href="/users" className="nav-link">Users</a></li>
                        <li><a href="/annual" className="nav-link">Annual Report</a></li>
                        <li><a href="/sheet" className="nav-link">Sheet</a></li>
                        <li><a href="/admin-dashboard" className="nav-link">Home</a></li>
                    </>
                )}
                <li><a href="/" className="nav-link" onClick={handleLogout}>Logout</a></li>
            </ul>
        </div>
    );
};

export default NavigationBar;
