import axios from "axios";
import io from "socket.io-client";

// Define a single variable to switch between environments
const isLiveEnvironment = true; // Set to false when you want to use the test environment

// Use the variable to choose between the test and live URLs
const BASE_URL = isLiveEnvironment ? "https://spotautos-api.onrender.com" : "http://localhost:3001";

const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

const socket = io(BASE_URL);

export { apiInstance, socket };
