import React from 'react';

const Leave = () => {
  return (
    <div>
      <h2>Leave</h2>
      {/* Content for Attendance page */}
    </div>
  );
}

export default Leave;
